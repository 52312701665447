<template>
  <v-footer app>
    <span>&copy; 2019</span>
  </v-footer>
</template>

<script>
export default {
  name: 'MyFooter',
  data: vm => ({}),
}
</script>
