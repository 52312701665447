<template>
  <v-snackbar
    v-model="$root.flashSnackbar"
    :color="$root.flashType"
    multi-line
    :timeout="6000"
    top
    @input="close"
  >
    {{ $root.flashMessage }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'FlashMessages',
  data: vm => ({}),
  methods: {
    close() {
      this.$root.flashSnackbar = false
      this.$root.flashMessage = ''
      this.$root.flashType = ''
    },
  },
}
</script>
