<template>
  <v-navigation-drawer
    v-model="$root.sideDrawer"
    floating
    persistent app
  >
    <v-app-bar flat color="#303030">
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center pt-6">
          <inertia-link href="/">
            <!--<logo style="fill: white" height="28" />-->
          </inertia-link>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-list dense shaped>
      <v-list-item-group
        :value="currentController"
        active-class="light-blue lighten-5 blue--text text--darken-4"
      >
        <v-list-item link value="/sites" @click="$inertia.visit(route('sites'))">
          <v-list-item-icon>
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Сайты
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link value="/cars" @click="$inertia.visit(route('cars'))">
          <v-list-item-icon>
            <v-icon>mdi-car</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Справочник Auto.ru
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link value="/images" @click="$inertia.visit(route('images'))">
          <v-list-item-icon>
            <v-icon>mdi-image-size-select-actual</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Фото
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <inertia-link href="/horizon" type="_blank">
          <v-list-item link value="/horizon">
            <v-list-item-icon>
              <v-icon>mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Очереди
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </inertia-link>

        <v-list-item link value="/logout" @click="$inertia.visit(route('logout'), {method: 'post'})">
          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Выход
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  data: vm => ({}),
  computed: {
    currentController() {
      const controller = location.pathname.substr(1).split('/').shift()
      return `/${controller}`
    },
  },
}
</script>
