import 'babel-polyfill'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import { InertiaApp } from '@inertiajs/inertia-vue'
import vuetify from '@/plugins/vuetify'
import ScrollSync from 'vue-scroll-sync'


require('@/plugins/registerComponents')

Vue.config.productionTip = false
Vue.mixin({ methods: { route: window.route } })
Vue.use(InertiaApp)
Vue.use(PortalVue)
Vue.use(VueMeta)

let app = document.getElementById('app')

window.eventBus = new Vue()

window.App = new Vue({
  vuetify,

  metaInfo: {
    title: 'Загрузка...',
    titleTemplate: '%s • CARS-DB SERVICE',
    changed(info){
      window.App.goBack = info.goBack
      window.App.appTitle = info.titleChunk;
    }
  },

  data: vm => ({
    appTitle: 'Загрузка...',
    goBack: null,
    sideDrawer: null,
    flashSnackbar: false,
    flashMessage: '',
    flashType: '',
  }),

  mounted() {
    eventBus.$on('flashMessage', (value, type) => {
      this.flashMessage = value
      this.flashType = type
      this.flashSnackbar = true
    })
  },

  render: h => h(InertiaApp, {
    props: {
      initialPage: JSON.parse(app.dataset.page),
      resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
      transformProps: props => {
        if (props.flash.success) {
            eventBus.$emit('flashMessage', props.flash.success, 'success')
        } else if (props.flash.error) {
            eventBus.$emit('flashMessage', props.flash.error, 'error')
        } else if (props.flash.warning) {
            eventBus.$emit('flashMessage', props.flash.warning, 'warning')
        }
        return props
      },
    },
  }),
}).$mount(app)
