<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12" lg="6">
        <v-autocomplete
          v-model="form.brand"
          :items="countries"
          auto-select-first
          chips
          clearable
          rounded
          solo
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          v-model="form.model"
          :error-messages="$page.errors.model"
          label="Модель"
          outlined
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          v-model="form.parse_url"
          :error-messages="$page.errors.parse_url"
          label="Ссылка для парсинга"
          outlined
        />
      </v-col>
      <!--      <v-col cols="12" lg="6">-->
      <!--        <v-text-field-->
      <!--          v-model="form.ignore_empty_price"-->
      <!--          :error-messages="$page.errors.ignore_empty_price"-->
      <!--          label="Игнорирование пустых цен"-->
      <!--          outlined-->
      <!--        />-->
      <!--      </v-col>-->
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'CarForm',

  props: {
    form: Object,
  },


  data: vm => ({
    countries: this.form.brands,
  }),
}
</script>
